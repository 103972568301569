<template>
  <Hero class="hero-home" :content="hero" :link="hero.link" title-tag="div">
    <!-- :label="heroLabel" :label-link="heroLabelLink" -->
    <template #top>
      <second-timer
        title="Успейте купить квартиру</br>по ипотеке с гос. поддержкой"
        subtitle="Осталось до окончания программы:"
        :date="new Date(2024, 5, 30, 24, 0)"
      />
      <a
        class="hero-home__key-label"
        href="https://gkosnova.ru/appointment/very-botsad/auth/login/"
        target="_blank"
      >
        <hero-key-label
          desc="Выдача ключей"
          orange
        />
      </a>
      <hero-key-label
        class="hero-home__stage-of-sale"
        title="Скоро старт продаж!"
        desc="2-Я ОЧЕРЕДЬ"
        hideIcon
      />
      <div class="hero-home__top d-none d-lg-block">
        <div class="container">
          Эко-квартал VERY на&nbsp;Ботанической, официальный сайт
        </div>
      </div>
    </template>
    <!-- <template #bottom>
      <div class="hero-home__bottom d-none d-lg-block">
        <div class="container">
          <ul v-if="$social">
            <li
              v-for="(value, name) in $social"
              :key="name"
            >
              <a
                :href="value.url"
                target="_blank"
                rel="noreferrer"
              >{{ value.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </template> -->
    <template #btns>
      <div class="btns-list d-md-flex justify-content-center">
        <div class="btns-list__item">
          <button
            data-popup-with-chats
            class="btn btn-default"
            data-popup-with-chats-title="Связаться со&nbsp;специалистом"
          >
            Связаться со&nbsp;специалистом
          </button>
        </div>
      </div>
    </template>
  </Hero>
</template>

<script>
import Hero from "@/components/Hero.vue";
import SecondTimer from "@/components/SecondTimer.vue";
import HeroKeyLabel from "@/components/home/HeroKeyLabel.vue";

export default {
  components: {
    Hero,
    SecondTimer,
    HeroKeyLabel,
  },
  inject: ["mainStore"],
  computed: {
    offer() {
      return this.mainStore.state.data?.main_offer;
    },
    hero() {
      return {
        title: this.offer?.title,
        text: this.offer?.subtitle,
        link: "",
        images: {
          srcSet: [
            {
              src: require("@/assets/i/banners/bannerDesk.webp"),
              media: 1280
            },
            {
              src: require("@/assets/i/banners/bannerDesk.jpg"),
              media: 1280
            },
            {
              src: require("@/assets/i/banners/bannerTablet.webp"),
              media: 768
            },
            {
              src: require("@/assets/i/banners/bannerTablet.jpg"),
              media: 768
            },
            {
              src: require("@/assets/i/banners/bannerMob.webp"),
              media: 0
            },
            {
              src: require("@/assets/i/banners/bannerMob.jpg"),
              media: 0
            },
          ],
          alt: "render",
          default: require("@/assets/i/banners/bannerDesk.jpg"),
        },
        more: "Начните знакомство с&nbsp;эко-кварталом",
      };
    },
    heroLabel() {
      let text = "Ипотека";
      if (this.mainStore.state.data?.mortgage.min_mortgage_percent) {
        text =
          "Ипотека от&nbsp;" +
          this.mainStore.state.data.mortgage.min_mortgage_percent +
          "%";
      }
      return text;
    },
    heroLabelLink() {
      return "Purchase";
    },
  },
};
</script>

<style scoped>
/* .hero-home__bottom {
		width: 100%;
		text-align: left;
		position: absolute;
		left: 0;
		bottom: 35px;
		font-size: 16px;
	}
	.hero-home__bottom ul {
		position: relative;
		display: inline-flex;
		margin: 0px;
		padding: 0px;
		list-style: none;
		z-index: 1;
	}
	.hero-home__bottom li {
		margin: 0px 25px 0px 0px;
		padding: 0px;
	}
	.hero-home__bottom li a {
		text-decoration: none;
	} */
@media (max-width: 767px) {
  .hero-home :deep(.content) {
    min-height: 600px;
  }
}

.hero-home :deep(.label h5),
.hero-home :deep(.label .h5) {
  padding: 11px 19px 12px;
  font-size: 19px;
  border-radius: 26px;
}
@media (max-width: 767px) {
  .hero-home :deep(.label h5),
  .hero-home :deep(.label .h5) {
    padding: 9px 16px;
    font-size: 15px;
  }
}

.hero-home :deep(.title) {
  max-width: 800px;
}
@media (min-width: 1280px) {
  .hero-home :deep(.title) {
    font-size: 104px;
  }
}

.hero-home :deep(.text) {
  max-width: 700px;
}

.hero-home :deep(.background::after) {
  background: rgb(104 116 69 / 0.5);
}

.hero-home__top {
  position: absolute;
  top: 110px;
  left: 0;
  width: 100%;
  font-size: 16px;
  text-align: left;
}
.hero-home__top h1 {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  margin: 0;
}

.hero-home__key-label {
  position: absolute;
  top: 83px;
  right: 8px;
  z-index: 3;
}

@media (min-width: 768px) {
  .hero-home__key-label {
    top: 84px;
    right: 12px;
  }
}
.hero-home__stage-of-sale {
  position: absolute;
  top: 137px;
  right: 8px;
  z-index: 3;
}

@media (min-width: 768px) {
  .hero-home__stage-of-sale {
    top: 155px;
    right: 12px;
  }
}
</style>
